import floating from "../imgs/what_floating.png";
function FloatingBtn() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      className="floating-btn"
      onClick={() => {
        openInNewTab("https://wa.me/+971504681550");
      }}
    >
      <img src={floating} alt="" />
      {/* <i class="fab fa-whatsapp"></i> */}
      {/* <i class="fas fa-angle-double-down fa-2x"></i> */}
    </div>
  );
}

export default FloatingBtn;
