import "./landing.css";
import { useTranslation } from "react-i18next";
function Landing(props) {
  const [t, i18n] = useTranslation();
  return (
    <div class="landing" id="landing">
      {/* <div class="text">
          <h1>{props.name} </h1>
          {props.name === t("main_title") && (
            <h3>
              {" "}
              {t("What about a website or mobile application for phones?")}{" "}
            </h3>
          )}
          <p>{props.disc}</p>
        </div>
      */}
      <div class="image">
        <img src={props.image} alt="" />
      </div>
      <a href="#articles" class="go-down">
        <i class="fas fa-angle-double-down fa-2x"></i>
      </a>
    </div>
  );
}

export default Landing;
