import { Link } from "react-router-dom";
import "./header.css";
import { useTranslation } from "react-i18next";

function Header(props) {
  const [t, i18n] = useTranslation();
  const handleClickScroll = (elementId) => {
    console.log("elementId", elementId);
    const element = document.getElementById(elementId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div class="header">
      <div class="container">
        <div
          className="change-lang"
          onClick={() => {
            i18n.language === "ar"
              ? i18n.changeLanguage("en")
              : i18n.changeLanguage("ar");
          }}
        >
          <i class="fa-solid fa-earth-americas"></i>
          <p> {i18n.language === "ar" ? "AR" : "EN"} </p>
        </div>

        <ul class="main-nav">
          <li>
            {props.isHome && (
              <a onClick={() => handleClickScroll("landing")}>{t("Home")}</a>
            )}
            {!props.isHome && (
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/"}
              >
                {t("Home")}{" "}
              </Link>
            )}
          </li>

          {props.isHome && (
            <li>
              <a onClick={() => handleClickScroll("ourServices")}>
                {t("Services")}
              </a>
            </li>
          )}
          {props.isHome && (
            <li>
              <a onClick={() => handleClickScroll("our-work")}>
                {" "}
                {t("Some Works")}
              </a>
            </li>
          )}
          <li>
            <a onClick={() => handleClickScroll("discount")}>
              {" "}
              {t("Make Order")}
            </a>
          </li>
          <li>
            <a onClick={() => handleClickScroll("callUs")}> {t("Call Us")}</a>
          </li>
          {/* <li>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/Privacy"}
            >
              {t("Privacy")}{" "}
            </Link>
          </li> */}
        </ul>

        <Link
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          to={"/"}
          class="logo"
        >
          ArabSkills
        </Link>
      </div>
    </div>
  );
}

export default Header;
