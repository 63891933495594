import { useTranslation } from "react-i18next";

function MobileSteps() {
  const [t, i18n] = useTranslation();
  return (
    <div className="serice-steps">
      <div className="container">
        <h2 class="main-title">{t("apps_steps")} </h2>
        <div class="timeline">
          <ul>
            <li>
              <div class="timeline-content">
                <h1>{t("Determine requirements and objectives")}</h1>
                <i class="fa-solid fa-bullseye"></i>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h1>{t("User interface design")} </h1>
                <i class="fa-solid fa-palette"></i>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h1>{t("Application programming")}</h1>
                <i class="fa-solid fa-code"></i>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h1>{t("Test the application")}</h1>

                <i class="fa-regular fa-circle-check"></i>
              </div>
            </li>
            <li>
              <div class="timeline-content">
                <h1>{t("Upload to Google Play and Apple Store")}</h1>

                <i class="fa-solid fa-upload"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MobileSteps;
