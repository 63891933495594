import Header from "../../components/header";
import Landing from "../../components/landing";
import Services from "./services";
import OurWorks from "./our-works";
import Footer from "../../components/footer";
import Request from "../../components/request";
import landing from "../../imgs/LCD/LCD_6.jpeg";
import WhoUs from "./who_us";
import { useTranslation } from "react-i18next";
function HomePage() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <Header isHome={true} />
      <Landing name={t("main_title")} disc={t("main_disc")} image={landing} />
      <Services />
      {/* <WhoUs/> */}
      <OurWorks />
      <Request />

      <Footer />
    </>
  );
}

export default HomePage;
