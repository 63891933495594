import "./request.css";
import callUs from "../imgs/call_us.png";
import { useState } from "react";
import { db } from "../firebase-config";
import { addDoc, collection } from "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
function Request() {
  const [t, i18n] = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const notify = (mess) =>
    toast.success(mess, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const createRequest = async (e) => {
    console.log(name, email, phone, message);
    e.preventDefault();
    await addDoc(collection(db, "request"), {
      name: name,
      email: email,
      phone: phone,
      message: message,
    })
      .then(() => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        notify("تم ارسال طلبك بنجاح");

        // alert("Your Request has been submitted👍");
      })
      .catch((error) => {
        // alert(error.message);
        notify(error.message);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="requstcard">
      <div className="container">
        <h2 class="main-title" id="discount">
          {t("Make Order")}{" "}
        </h2>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <div class="discount">
          <div class="form">
            <div class="content">
              <h2></h2>
              <form onSubmit={createRequest}>
                <div className="form">
                  <div className="content">
                    <input
                      className="input"
                      type="text"
                      placeholder={t("name")}
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                    <input
                      className="input"
                      type="email"
                      placeholder={t("email")}
                      name="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    <input
                      className="input"
                      type="text"
                      placeholder={t("phone")}
                      name="mobile"
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                    <textarea
                      className="input"
                      placeholder={t("how we can help us")}
                      name="message"
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                    ></textarea>

                    <input type="submit" value={t("send")} />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="image">
            <div class="content">
              <img src={callUs} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Request;

// <div className="discount" id="discount">
// <ToastContainer
//   position="top-right"
//   autoClose={5000}
//   hideProgressBar={false}
//   newestOnTop={false}
//   closeOnClick
//   rtl={false}
//   pauseOnFocusLoss
//   draggable
//   pauseOnHover
//   theme="colored"
// />

// <div className="container">
//   <form onSubmit={createRequest}>
//     <div className="form">
//     <h2 class="main-title">طلب خدمة</h2>

//       <div className="content">

//         <div className="image">
//         <div className="content">
//           <h2>We Have A Discount</h2>
//           <p>
//             Lorem ipsum dolor sit amet consectetur, adipisicing elit.
//             Excepturi asperiores consectetur, recusandae ratione provident
//             necessitatibus, cumque delectus commodi fuga praesentium
//             beatae. Totam vel similique laborum dicta aperiam odit
//             doloribus corporis.
//           </p>
//           <img src="imgs/discount.png" alt="" />
//         </div>
//       </div>
//         <input
//           className="input"
//           type="text"
//           placeholder="الاسم"
//           name="name"
//           onChange={(event) => {
//             setName(event.target.value);
//           }}
//         />
//         <input
//           className="input"
//           type="email"
//           placeholder="الايميل"
//           name="email"
//           onChange={(event) => {
//             setEmail(event.target.value);
//           }}
//         />
//         <input
//           className="input"
//           type="text"
//           placeholder="رقم الهاتف"
//           name="mobile"
//           onChange={(event) => {
//             setPhone(event.target.value);
//           }}
//         />
//         <textarea
//           className="input"
//           placeholder="كيف يمكننا مساعدتك ؟ "
//           name="message"
//           onChange={(event) => {
//             setMessage(event.target.value);
//           }}
//         ></textarea>

//         <input type="submit" value="Send" />
//       </div>
//     </div>
//   </form>
// </div>
// </div>
