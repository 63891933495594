import Header from "../../components/header";
import Footer from "../../components/footer";
import Request from "../../components/request";
import Landing from "../../components/landing";
import landingImage from "../../imgs/new-landing.svg";
import WhatWeb from "./what_web";
import WebImportance from "./web_importance";
import WebSteps from "./web_steps";
import { useTranslation } from "react-i18next";
import ImageSlider from "../../components/image_solider/image_slider";
import image1 from "../../imgs/D-Screen/D-01.jpeg";
import image2 from "../../imgs/D-Screen/D-02.png";
import image3 from "../../imgs/D-Screen/D-03.jpg";
import image4 from "../../imgs/D-Screen/D-04.png";
import image5 from "../../imgs/D-Screen/D-05.jpg";

const D_images = [image1, image2, image3, image4, image5];
function WebsitePage() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <Header isHome={false} />
      <ImageSlider images={D_images} />

      {/* <WhatWeb /> */}
      {/* <WebImportance /> */}
      {/* <WebSteps /> */}
      <Request />

      <Footer />
    </>
  );
}

export default WebsitePage;
