import "./our-works.css";
import haddaf from "../../imgs/LCD/project1.jpg";
import services from "../../imgs/LCD/project2.jpg";
import home from "../../imgs/LCD/project3.jpeg";
import { useTranslation } from "react-i18next";
import googleIcon from "../../imgs/google_play.svg";
import appleIcon from "../../imgs/apple_store.svg";

function OurWorks() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <div class="team" id="our-work">
        <h2 class="main-title">{t("Some Works")}</h2>
        <div class="container">
          <Card
            name="Elhaddaf"
            disc="Sports Servics app"
            image={haddaf}
            googlePlay="https://play.google.com/store/apps/details?id=com.alhaddafcommunity.co&hl=ar&gl=US"
            appleStore="https://play.google.com/store/apps/details?id=com.alhaddafcommunity.co&hl=ar&gl=US"
          />
          <Card
            name="Top Service"
            disc="Home Servics app"
            image={services}
            googlePlay="https://play.google.com/store/apps/details?id=com.aoun_service.H"
            appleStore="https://apps.apple.com/us/app/top-توب/id6473125859"
          />
          <Card
            name="Home Cure"
            disc="Health care app"
            image={home}
            googlePlay="https://play.google.com/store/apps/details?id=com.home_cure"
            appleStore="https://apps.apple.com/eg/app/home-cure/id1670011499"
          />
        </div>
      </div>
    </>
  );
}

function Card(props) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="big-box">
      <div class="box">
        <div class="data">
          <img src={props.image} alt="" />
        </div>
        {/* <div class="info">
          <h3>{props.name}</h3>
          <p>{props.disc}</p>
        </div> */}
      </div>

      {/* <div className="social">
        <div
          className="store-btn"
          onClick={() => {
            openInNewTab(props.googlePlay);
          }}
        >
          <img src={googleIcon} alt="Google Play" />
          <div className="text">
            <p> Try it now</p>
            <h4>Google Play</h4>
          </div>
        </div>
        <div
          className="store-btn"
          onClick={() => {
            openInNewTab(props.appleStore);
          }}
        >
          <img src={appleIcon} alt="Apple Store" />
          <div className="text">
            <p> Try it now</p>
            <h4>Apple Store</h4>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default OurWorks;
