import "./services.css";
import our_web from "../../imgs/screen_right.png";
import our_apps from "../../imgs/screen_left.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Services() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <div class="features" id="ourServices">
        <h2 class="main-title">{t("Services")}</h2>
        <div class="container">
          <div class="box time">
            <div class="img-holder">
              <img src={our_web} alt="" />
            </div>
            <h2> {t("Website design and programming")}</h2>
            <p>{t("web_disc")}</p>
            <Link href="#" to={"website"}>
              {t("more")}
            </Link>
          </div>
          <div class="box passion">
            <div class="img-holder">
              <img src={our_apps} alt="" />
            </div>
            <h2>{t("Design and programming mobile applications")}</h2>
            <p>{t("app_disc")}</p>
            <Link href="#" to={"mobile"}>
              {t("more")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
