import "./App.css";
// index.js or index.jsx
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FloatingBtn from "./components/floating_btn";
import HomePage from "./pages/home/home_page";
import MobilePage from "./pages/mobile/mobile_page";
import WebsitePage from "./pages/website/website_page";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./ScrollToTop";
import Privacy from "./pages/privacy/privacy";

function App() {
  const [t, i18n] = useTranslation();
  return (
    <div className="App" dir={i18n.dir() === "ltr" ? "rtl" : "ltr"}>
      <FloatingBtn />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="mobile" element={<MobilePage />} />
          <Route path="website" element={<WebsitePage />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
