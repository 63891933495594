import Header from "../../components/header";
import Footer from "../../components/footer";
import Request from "../../components/request";
import Landing from "../../components/landing";
import landing from "../../imgs/new-landing.svg";
import MobileImportance from "./mobile_importance";
import WhatMobile from "./what_mobile";
import MobileSteps from "./mobile_steps";
import { useTranslation } from "react-i18next";
import image6 from "../../imgs/M-Screen/M-01.jpg";
import image7 from "../../imgs/M-Screen/M-02.jpeg";
import image8 from "../../imgs/M-Screen/M-03.jpeg";
import image9 from "../../imgs/M-Screen/M-04.jpg";
import image10 from "../../imgs/M-Screen/M-05.jpg";
import image11 from "../../imgs/M-Screen/M-06.jpg";
import ImageSlider from "../../components/image_solider/image_slider";
const M_images = [image7, image8, image9, image10, image11];

function MobilePage() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <Header isHome={false} />
      <ImageSlider images={M_images} />
      {/* <Landing
        name={t("Design and programming mobile applications")}
        disc={t("main_disc")}
        image={landing}
      />

      <WhatMobile />
      <MobileImportance />
      <MobileSteps /> */}
      <Request />
      <Footer />
    </>
  );
}

export default MobilePage;
