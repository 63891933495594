
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTdQHMPdeUFa8b9Dokz21c-u7yKbBm2xg",
  authDomain: "lcd-jo.firebaseapp.com",
  projectId: "lcd-jo",
  storageBucket: "lcd-jo.appspot.com",
  messagingSenderId: "401970628018",
  appId: "1:401970628018:web:8c62dd2fc8a95d54de10ab",
  measurementId: "G-KEDJNH67ZR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export  const db =getFirestore(app);

