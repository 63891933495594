import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Request from "../../components/request";
import Footer from "../../components/footer";

const Privacy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch("/priv.html");
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };

    fetchHtmlContent();
  }, []);

  return (
    <>
      <Header isHome={false} />

      <div
        style={{ textAlign: "left", direction: "ltr", padding: "20px" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />

      <Request />
      <Footer />
    </>
  );
};

export default Privacy;
