import { Link } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";
function Footer() {
  const [t, i18n] = useTranslation();
  return (
    <>
      <div class="footer" id="callUs">
        <div class="container">
          <div class="box">
            <h3>ArabSkills </h3>
            <ul class="social">
              <li>
                <a
                  href="https://www.facebook.com/ArabSkills.fb"
                  target="_blank"
                  class="facebook"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@arab.skills"
                  target="_blank"
                  class="facebook"
                >
                  <i class="fa-brands fa-tiktok"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/arab.skill/"
                  target="_blank"
                  class="instagram"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/+971504681550"
                  target="_blank"
                  class="whatsapp"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="box">
            <div class="line">
              <i class="fas fa-map-marker-alt fa-fw"></i>
              <div class="info">
                <span>Amman - Jordon</span>
                <span>Emirates - Dubai</span>
              </div>
            </div>
            <div class="line">
              <i class="far fa-clock fa-fw"></i>
              <div class="info">Business Hours: 8:00 AM : 5:00 PM</div>
            </div>
            <div class="line">
              <i class="fas fa-phone-volume fa-fw"></i>
              <div class="info">
                <span>+962 7 8716 5522</span>
                <span>+971 5 0468 1550</span>
              </div>
            </div>
          </div>
        </div>
        <p class="copyright">
          {t("copy_writes1")}
          <br />
          {t("copy_writes2")}
          <br />
          <br />
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={"/Privacy"}
            style={{ color: "white", textDecoration: "underline" }}
          >
            {t("Privacy")}{" "}
          </Link>
        </p>
      </div>
    </>
  );
}

export default Footer;
